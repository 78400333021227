<ul class="nav navbar-nav bookmark-icons">
    <ng-container>
        <li class="nav-item d-none d-lg-block">
            <a class="nav-link"  disabled="true" placement="bottom" ngbTooltip="{{ 'CNN' }}">
                <span [data-feather]="'bar-chart-2'" [class]="'ficon'"></span>
            </a>
        </li>
        <li class="nav-item d-none d-lg-block">
            <a class="nav-link" [routerLink]="'credenciado/dashboard'" placement="bottom"
               ngbTooltip="{{ 'Intermediações' }}">
                <span [data-feather]="'shopping-cart'" [class]="'ficon'"></span>
            </a>
        </li>


    </ng-container>
</ul>
