import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import {AutenticacaoService} from 'app/shared/clients/user-client';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
    /**
     *
     * @param {Router} _router
     * @param {AutenticacaoService} _authenticationService
     */
    constructor(private _router: Router, private _authenticationService: AutenticacaoService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser: any = this._authenticationService.currentUserValue;
        this._authenticationService.me().subscribe(data => {
            localStorage.setItem('currentUser', JSON.stringify(data));
            this._authenticationService.setCurrentUserValue(data)
        })

        if (currentUser) {
            currentUser.screen_principal = currentUser.acl.funcionalidades[0].url;
            if (Array.isArray(route.data.funcionalidade)) {
                let hasPermission = false;

                for (const funcionalidade of route.data.funcionalidade) {
                    if (currentUser.acl.funcionalidades.some(role => role.id === funcionalidade)) {
                        hasPermission = true;
                        break;
                    }
                }

                if (!hasPermission) {
                    this._router.navigate(['/ex/nao-autorizado']);
                    return false;
                }
            } else if (!currentUser.acl.funcionalidades.some(role => role.id === route.data.funcionalidade)) {
                this._router.navigate([currentUser.screen_principal]);
                return false;
            }


            return true;
        }

        this._router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
        return false;
    }
}
