<li ngbDropdown class="nav-item dropdown-notification mr-25">
    <a class="nav-link" ngbDropdownToggle id="navbarNotificationDropdown">
        <i class="ficon" data-feather="briefcase"></i>
        <span class="badge badge-pill badge-success badge-up">
      {{ selectedCompanies.length }}
    </span>
    </a>
    <ul
            ngbDropdownMenu
            aria-labelledby="navbarNotificationDropdown"
            class="dropdown-menu dropdown-menu-media dropdown-menu-right"
    >
        <!-- Notifications header -->
        <li class="dropdown-menu-header">
            <div class="dropdown-header d-flex">
                <h4 class="notification-title mb-0 mr-auto">Credenciados</h4>
                <div class="badge badge-pill badge-light-primary">
                    {{ selectedCompanies.length }} Selecionados
                </div>
            </div>
        </li>
        <form>
            <div class="mb-3">
                <input
                        type="text"
                        class="form-control"
                        placeholder="Filtrar grupos ou empresas"
                        [(ngModel)]="filterText"
                        (ngModelChange)="applyFilter()"
                        name="search"
                />
            </div>

<!--            <div *ngFor="let group of filteredCompanies" class="mb-1">-->
<!--                <div class="form-check">-->
<!--                    <input-->
<!--                            class="form-check-input"-->
<!--                            type="checkbox"-->
<!--                            [id]="'group' + group.grupo_economico_slug"-->
<!--                            (change)="onGroupSelected(group.grupo_economico_slug, $event.target.checked)"-->
<!--                            [checked]="isGroupSelected(group.grupo_economico_slug)"-->
<!--                    />-->
<!--                    <label class="form-check-label font-weight-bold" [for]="'group' + group.grupo_economico_slug">-->
<!--                        {{ group.grupo_economico_slug }}-->
<!--                    </label>-->
<!--                </div>-->

<!--                &lt;!&ndash; Checkbox para selecionar as empresas individualmente &ndash;&gt;-->
<!--                <div class="ml-4">-->
<!--                    <div class="form-check" *ngFor="let company of group.companies">-->
<!--                        <input-->
<!--                                class="form-check-input"-->
<!--                                type="checkbox"-->
<!--                                [id]="'company' + company._id"-->
<!--                                [value]="company._id"-->
<!--                                (change)="onCompanySelected(company._id, $event.target.checked)"-->
<!--                                [checked]="selectedCompanies.includes(company.id)"-->
<!--                        />-->
<!--                        <label class="form-check-label" [for]="'company' + company._id">-->
<!--                            {{ company.nome_fantasia }}-->
<!--                        </label>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
        </form>


        <li class="dropdown-menu-footer">
            <a class="btn btn-primary btn-block" href="javascript:void(0)" (click)="selectAllCompanies()">Marcar
                todos</a>
        </li>
        <!--/ Notifications footer -->
    </ul>
</li>