import {Component, OnInit} from '@angular/core';

import {NotificationsService} from 'app/layout/components/navbar/navbar-notification/notifications.service';
import {AutenticacaoService} from "../../../../shared/clients/user-client";
import { Console } from 'console';

// Interface
interface notification {
    messages: [];
    systemMessages: [];
    system: Boolean;
}

@Component({
    selector: 'app-navbar-credenciado',
    styleUrls: ['./navbar-credenciado.component.scss'],
    templateUrl: './navbar-credenciado.component.html'
})
export class NavbarCredenciadoComponent {
    companies = [];
    selectedCompanies: string[] = [];
    groupedCompanies = [];
    filteredCompanies = [];
    filterText = '';
    groupStates: { [key: string]: boolean } = {};
    expandedGroups: { [key: string]: boolean } = {};

    constructor(private autenticacaoService: AutenticacaoService) {
        this.autenticacaoService.obterCredenciados().subscribe(data => {
            this.companies = data.dados;
            this.groupCompaniesBygrupo_economico_slug();


            this.filteredCompanies = data.dados; // Inicialmente, exibe todos os grupos

            if(this.companies?.length == 1){
                this.selectAllCompanies();
            }
        })

        const currentCnpjString = localStorage.getItem('currentCnpj');
        
        // Verifica se o valor existe no localStorage
        if (currentCnpjString) {
            this.selectedCompanies = currentCnpjString.split(',').map(cnpj => cnpj.trim());
        } else {
            this.selectedCompanies = []; // Valor padrão se não houver nada no localStorage
        }   
    }

    ngOnInit(): void {
        const currentCnpjString = localStorage.getItem('currentCnpj');
        this.selectedCompanies = currentCnpjString
            ? currentCnpjString?.split(',').map(cnpj => cnpj.trim())
            : [];
    }

    // Função para agrupar as empresas por grupo econômico
    groupCompaniesBygrupo_economico_slug() {
        const grouped = this.companies.reduce((groups, company) => {
            // Definindo um valor padrão para grupo_economico_slug quando for undefined, null ou vazio
            const groupNameSlug = company.grupo_economico_slug || 'Sem Grupo';
            const groupName = company.grupo_economico_nome || 'Sem Grupo';
    
            // Verificando se o grupo já existe
            const group = groups.find(g => g.groupNameSlug === groupNameSlug);
            if (group) {
                group.companies.push(company);
            } else {
                groups.push({ groupNameSlug: groupNameSlug, groupName: groupName, companies: [company] });
            }
            return groups;
        }, []);
    
        this.groupedCompanies = grouped;
    }

    // Função para verificar se todos os CNPJs de um grupo estão selecionados
    isGroupSelected(groupName: string): boolean {
        const group = this.groupedCompanies.find(g => g.groupName === groupName);
        return group.companies.every(company => this.selectedCompanies.includes(company.cnpj));
    }

    applyFilter() {
        const lowerCaseFilter = this.filterText.toLowerCase();
        this.groupCompaniesBygrupo_economico_slug();

        this.filteredCompanies = this.groupedCompanies
            .map(group => ({
                ...group,
                companies: group.companies.filter(company =>
                    company.nome_fantasia.toLowerCase().includes(lowerCaseFilter))
            }))
            .filter(group =>
                group.groupName?.toLowerCase().includes(lowerCaseFilter) || group.companies.length > 0);
        this.groupedCompanies = this.filteredCompanies;
    }

    // Selecionar/deselecionar todas as empresas de um grupo
    onGroupSelected(groupName: string, isChecked: boolean) {
        const group = this.groupedCompanies.find(g => g.groupName === groupName);
        if (group) {
            group.companies.forEach(company => {
                if (isChecked) {
                    if (!this.selectedCompanies.includes(company.cnpj)) {
                        this.setarCredenciado(company.cnpj, true);
                    }
                } else {
                    this.setarCredenciado(company.cnpj, false);
                }
            });
        }
    }

    // Selecionar/deselecionar uma empresa individualmente
    onCompanySelected(companyId: number, isChecked: boolean, cnpj: string) {
        if (isChecked) {
            this.setarCredenciado(cnpj, true);
        } else {
            this.setarCredenciado(cnpj, false);
        }
    }

    // Selecionar todas as empresas
    selectAllCompanies() {
        this.companies.forEach(company => {
            this.setarCredenciado(company.cnpj, true);
        });
    }

    toggleGroup(groupName: string): void {
        this.expandedGroups[groupName] = !this.expandedGroups[groupName];
      }
    
    isGroupExpanded(groupName: string): boolean {
        return this.expandedGroups[groupName] || false;
    }

    setarCredenciado(cnpj: string, isAdding: boolean) {
        let currentCnpjs = localStorage.getItem('currentCnpj') || '';
        let cnpjArray = currentCnpjs ? currentCnpjs?.split(',').map(c => c.trim()) : [];
    
        if (isAdding) {
            if (!cnpjArray.includes(cnpj)) {
                cnpjArray.push(cnpj);
            }
        } else {
            cnpjArray = cnpjArray.filter(c => c !== cnpj);
        }
    
    if (cnpjArray.length === 0) {
        localStorage.removeItem('currentCnpj');
    } else {
        localStorage.setItem('currentCnpj', cnpjArray.join(','));
    }
    this.selectedCompanies = cnpjArray.map(c => c);
    }

    // Verifica se todas as empresas estão selecionadas
    areAllCompaniesSelected(): boolean {
        return this.companies.length > 0 && this.selectedCompanies.length === this.companies.length;
    }
  
    // Alterna entre selecionar e desmarcar todas as empresas
    toggleSelectAll() {
        if (this.areAllCompaniesSelected()) {
        this.deselectAllCompanies();
        } else {
        this.selectAllCompanies();
        }
    }
    
    // Função para desmarcar todas as empresas
    deselectAllCompanies() {
        this.companies.forEach(company => {
        this.setarCredenciado(company.cnpj, false);
        });
    }
    
}
