<li ngbDropdown class="nav-item dropdown-notification mr-25">
  <a class="nav-link" ngbDropdownToggle id="navbarNotificationDropdown" ngbTooltip="Credenciados">
    <i class="ficon" data-feather="briefcase"></i>
    <span class="badge badge-pill badge-success badge-up">
      {{ selectedCompanies.length }}
    </span>
  </a>
  <ul
    ngbDropdownMenu
    aria-labelledby="navbarNotificationDropdown"
    class="dropdown-menu dropdown-menu-media dropdown-menu-right"
  >
    <!-- Cabeçalho do Dropdown -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex align-items-center justify-content-between">
        <h4 class="notification-title mb-0">Credenciados</h4>
        <div class="badge badge-pill badge-light-primary">
          {{ selectedCompanies.length }} Selecionados
        </div>
      </div>
    </li>

    <!-- Formulário com Filtro e Listagem -->
    <form>
      <div>
        <input
          type="text"
          class="form-control"
          placeholder="Filtrar Grupo ou Credenciado"
          [(ngModel)]="filterText"
          (ngModelChange)="applyFilter()"
          name="search"
        />
      </div>

      <div class="business-items">
        <div *ngFor="let group of groupedCompanies" class="mb-1">
          <!-- Se o grupo for "Sem Grupo", exibir empresas diretamente -->
          <ng-container *ngIf="group.groupName === 'Sem Grupo'; else groupedTemplate">
            <div class="form-check" *ngFor="let company of group.companies">
              <input
                class="form-check-input"
                type="checkbox"
                [id]="company.cnpj"
                [value]="company.cnpj"
                (change)="onCompanySelected(company._id, $event.target.checked, company.cnpj)"
                [checked]="selectedCompanies.includes(company.cnpj)"
              />
              <label class="form-check-label" [for]="'company' + company.cnpj">
                {{ company.nome_fantasia }} - {{ company.cnpj | cpfCnpj }}
              </label>
            </div>
          </ng-container>

          <!-- Template padrão para grupos normais -->
          <ng-template #groupedTemplate>
            <div class="group-header d-flex align-items-center">
              <input
                type="checkbox"
                class="mr-2"
                [checked]="isGroupSelected(group.groupName)"
                (change)="onGroupSelected(group.groupName, $event.target.checked)"
              />
              <label class="font-weight-bold">
                <b>{{ group.groupName }}</b>
              </label>
              <button
                class="btn btn-sm btn-link p-0 mr-2"
                (click)="toggleGroup(group.groupName)"
                [attr.aria-expanded]="isGroupExpanded(group.groupName)"
              >
                <span *ngIf="isGroupExpanded(group.groupName)">[-]</span>
                <span *ngIf="!isGroupExpanded(group.groupName)">[+]</span>
              </button>
            </div>

            <!-- Lista de Empresas (exibida com base no estado do grupo) -->
            <div
              class="ml-4 company-list"
              *ngIf="isGroupExpanded(group.groupName)"
            >
              <div class="form-check" *ngFor="let company of group.companies">
                <input
                  class="form-check-input"
                  type="checkbox"
                  [id]="company.cnpj"
                  [value]="company.cnpj"
                  (change)="onCompanySelected(company._id, $event.target.checked, company.cnpj)"
                  [checked]="selectedCompanies.includes(company.cnpj)"
                />
                <label class="form-check-label" [for]="'company' + company.cnpj">
                  {{ company.nome_fantasia }} - {{ company.cnpj }}
                </label>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </form>

    <!-- Rodapé do Dropdown -->
    <li class="dropdown-menu-footer">
      <a
        class="btn btn-primary btn-block"
        href="javascript:void(0)"
        (click)="toggleSelectAll()"
      >
        {{ areAllCompaniesSelected() ? 'Desmarcar todos' : 'Marcar todos' }}
      </a>
    </li>
  </ul>
</li>
