import {Component, OnInit} from '@angular/core';

import {NotificationsService} from 'app/layout/components/navbar/navbar-notification/notifications.service';
import {AutenticacaoService} from "../../../../shared/clients/user-client";

// Interface
interface notification {
    messages: [];
    systemMessages: [];
    system: Boolean;
}

@Component({
    selector: 'app-navbar-credenciado',
    templateUrl: './navbar-credenciado.component.html'
})
export class NavbarCredenciadoComponent {
    companies = [];

    selectedCompanies: number[] = [];
    groupedCompanies = [];
    filteredCompanies = [];
    filterText = '';

    constructor(private autenticacaoService: AutenticacaoService) {

        this.autenticacaoService.obterCredenciados().subscribe(data => {

            this.companies = data.dados;
            this.groupCompaniesBygrupo_economico_slug();

            this.filteredCompanies = data.dados; // Inicialmente, exibe todos os grupos
        })
    }

    // Função para agrupar as empresas por grupo econômico
    groupCompaniesBygrupo_economico_slug() {
        const grouped = this.companies.reduce((groups, company) => {
            const group = groups.find(g => g.groupName === company.grupo_economico_slug);
            if (group) {
                group.companies.push(company);
            } else {
                groups.push({groupName: company.grupo_economico_slug, companies: [company]});
            }
            return groups;
        }, []);

        this.groupedCompanies = grouped;
    }

    // Função para verificar se todos os CNPJs de um grupo estão selecionados
    isGroupSelected(groupName: string): boolean {
        const group = this.groupedCompanies.find(g => g.groupName === groupName);
        return group.companies.every(company => this.selectedCompanies.includes(company.id));
    }

    applyFilter() {
        const lowerCaseFilter = this.filterText.toLowerCase();

        this.filteredCompanies = this.groupedCompanies
            .map(group => ({
                ...group,
                companies: group.companies.filter(company =>
                    company.nome_fantasia.toLowerCase().includes(lowerCaseFilter))
            }))
            .filter(group =>
                group.groupName.toLowerCase().includes(lowerCaseFilter) || group.companies.length > 0);
    }

    // Selecionar/deselecionar todas as empresas de um grupo
    onGroupSelected(groupName: string, isChecked: boolean) {
        const group = this.groupedCompanies.find(g => g.groupName === groupName);
        if (group) {
            group.companies.forEach(company => {
                if (isChecked) {
                    if (!this.selectedCompanies.includes(company.id)) {
                        this.selectedCompanies.push(company.id);
                    }
                } else {
                    this.selectedCompanies = this.selectedCompanies.filter(id => id !== company.id);
                }
            });
        }
    }

    // Selecionar/deselecionar uma empresa individualmente
    onCompanySelected(companyId: number, isChecked: boolean) {
        if (isChecked) {
            this.selectedCompanies.push(companyId);
        } else {
            this.selectedCompanies = this.selectedCompanies.filter(id => id !== companyId);
        }
    }

    // Selecionar todas as empresas
    selectAllCompanies() {
        this.selectedCompanies = this.companies.map(company => company.id);
    }
}
